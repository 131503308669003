<template>
  <div ref="menuItems">
    <div class="sidebar-left__content-header">
      <router-link class="overviewLink" :to="{ name: overviewRoute }">{{ title }}</router-link>
    </div>
    <div class="sidebar-left__menu">
      <ul>
        <li v-for="(page, i) in pages" :key="i">
          <AccordionItem :items="page.children">
            <template v-slot:title
              ><label>{{ page.name }}</label></template
            >
            <template v-slot:content>
              <ul>
                <li v-for="(child, i) in page.children" :key="i" class="cell">
                  <router-button
                    v-if="child.exists"
                    :cssClass="child.slug ? '' : 'inactive'"
                    :routeName="detailRoute"
                    :routeParam="child.slug"
                    :text="child.name"
                  ></router-button>
                  <a
                    v-else
                    @click="createPage('BrandDetailEdit', child)"
                    class="inactive"
                  >
                    <span>{{ child.name }}</span>
                  </a>
                </li>
              </ul>
            </template>
          </AccordionItem>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AccordionItem from '@/views/common/components/elements/AccordionItem'
import NewPageModal from '@/views/brand/components/NewPageModal'

export default {
  name: 'BrandNavigation',
  props: {
    pages: Array,
    title: String,
    detailRoute: String,
    overviewRoute: String
  },
  methods: {
    createPage (routeName, page) {
      this.$modal.show(
        NewPageModal,
        {
          routeName: routeName,
          page: page
        },
        {
          draggable: false,
          scrollable: false,
          classes: 'text-center',
          height: 'auto',
          width: '900px'
        }
      )
    }
  },
  components: { AccordionItem }
}
</script>

<style scoped>
.overviewLink {
  text-decoration: none;
  color: var(--menu-secondary-icon-color);
}
</style>
