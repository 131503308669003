<template>
  <div>
    <div class="accordion" @click="toggleItem" :class="show ? 'closable' : ''">
      <slot name="title"></slot>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div v-show="show" class="content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: ['items'],
  data: function () {
    return {
      show: false
    }
  },
  mounted () {
    this.items.forEach(item => {
      if (item.slug === this.$route.params.pathName) {
        this.show = true
      }
    })
  },
  methods: {
    toggleItem: function () {
      this.show = !this.show
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  transition: 150ms ease-out;
}

.accordion {
  position: relative;
  $plus-size: 14px;

  &::before,
  &::after {
    transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;
    position: absolute;
    content: "";
    background: var(--primary-color);
    display: inline-block;
    opacity: 0;
  }

  &::before {
    height: $plus-size;
    width: 2px;
    right: 36px;
    top: 14px;
  }

  &::after {
    width: $plus-size;
    height: 2px;
    right: 30px;
    top: 20px;
  }

  &:hover,
  &.closable {
    &::before,
    &::after {
      opacity: 1;
    }
  }

  &.closable {
    &::before {
      transform: rotate(-90deg) translateY(-50%);
      right: 30px;
    }

    &::after {
    }
  }
}
</style>
