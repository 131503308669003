<template>
  <div>
    <span v-if="templates">
      <h1>{{ $t('brand.create', { page: page.name }) }}</h1>
      <span
        @click="addTemplate"
        class="new-page-button"
        v-if="templates.length > 0"
      >
        <span class="new-page-button__icon">
          <i class="icon-create-page-template"></i>
        </span>
        <h4>{{ $t('builder.use_template_title') }}</h4>
        <p>{{ $t('builder.use_template_description') }}</p>
        <div class="spacer--20"></div>
        <span class="button-branded">{{ $t('builder.use_template_button') }}</span>
      </span>
      <span class="new-page-button disabled" v-else>
        <span class="new-page-button__icon">
          <i class="icon-create-page-template"></i>
        </span>
        <h4>{{ $t('builder.use_template_title') }}</h4>
        <p>{{ $t('builder.use_template_description') }}</p>
        <div class="spacer--20"></div>
        <span class="button-branded button-branded--share">{{ $t('builder.use_template_button') }}</span>
      </span>
      <span @click="addBuildingBlocks" class="new-page-button">
        <span class="new-page-button__icon">
          <i class="icon-create-page-builder"></i>
        </span>
        <h4>{{ $t('builder.empty_canvas_title') }}</h4>
        <p>{{ $t('builder.empty_canvas_description') }}</p>
        <div class="spacer--20"></div>
        <span class="button-branded button-branded--share">{{ $t('builder.empty_canvas_button') }}</span>
      </span>
      <span @click="showDescribe" class="new-page-button">
        <span class="new-page-button__icon">
          <i class="icon-create-page-explenation"></i>
        </span>
        <h4>{{ $t('brand.watch_title') }}</h4>
        <p>{{ $t('brand.watch_description') }} {{ $t('brand.watch_part', { page: page.name }) }}</p>
        <div class="spacer--20"></div>
        <span class="button-branded button-branded--share">{{ $t('brand.watch_button') }}</span>
      </span>
    </span>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'NewPageModal',
  props: {
    routeName: String,
    page: Object
  },
  data () {
    return {
      templates: null
    }
  },
  mounted () {
    backend
      .get(`${process.env.VUE_APP_URL}/pages/${this.page.slug}/templates`)
      .then(response => {
        this.templates = response.data
      })
  },
  methods: {
    showDescribe () {
      this.$router.push({
        name: 'BrandDescribe',
        params: { pathName: this.page.slug }
      })
      this.$emit('close')
    },
    addBuildingBlocks () {
      this.$router.push({
        name: this.routeName,
        params: { pathName: this.page.slug }
      })
      this.$emit('close')
    },
    addTemplate () {
      /**
       * For now we will only support 1 template. Choose the first one.
       */
      const selectedTemplate = this.templates[0].id
      this.$router.push({
        name: this.routeName,
        params: { pathName: this.page.slug, template: selectedTemplate }
      })
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  color: #fff;
  padding-bottom: 40px;
}

span {
  cursor: pointer;
}

.new-page-button {
  width: 250px;
  height: auto;
  background: $white;
  border: solid 2px $white;
  box-shadow: 0 0 15px rgba(black, 0.1);
  display: inline-block;
  text-align: center;
  padding: 30px;
  border-radius: $global-radius;

  h4 {
    margin-bottom: calc(10px);
  }

  p {
    line-height: calc(18px);
  }

  &:hover {
    border: solid 2px var(--primary-color);
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;

    .disabled__icon {
      cursor: not-allowed;
    }
  }

  + span {
    margin-left: 50px;
  }

  &__icon {
    $icon-size: 100%;
    width: $icon-size;
    height: $icon-size;
    display: block;
    margin: 0 auto 20px;

    svg {
      width: 100%;
      margin-top: 5px;
    }

    i {
      font-size: 120px;
      vertical-align: middle;
      line-height: 150px;
      color: var(--primary-color);
    }
  }
}
</style>
