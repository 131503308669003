<template>
  <default-layout>
    <portal to="sidebarContent">
      <BrandNavigation :pages="pages" :title="title" :detailRoute="brandDetailRoute" :overviewRoute="overviewRoute"></BrandNavigation>
    </portal>
    <div class="grid-x grid-margin-x">
      <div class="cell page__inner-content">
        <vue-page-transition name="fade">
          <router-view :pages="pages" @updateBrandPages="getBrandPages"></router-view>
        </vue-page-transition>
      </div>
    </div>
  </default-layout>
</template>

<script>
import backend from '@/backend'

import BrandNavigation from './components/BrandNavigation'

export default {
  name: 'BrandLayout',
  data () {
    return {
      pages: [],
      title: ''
    }
  },
  methods: {
    getBrandPages () {
      backend.get(`${process.env.VUE_APP_URL}/brand-pages/positioning`).then(response => {
        this.pages = response.data.pages
        this.title = this.$t('navigation.positioning')
      })
    },
    getBrandGuidelines () {
      backend.get(`${process.env.VUE_APP_URL}/brand-pages/guidelines`).then(response => {
        this.pages = response.data.pages
        this.title = this.$t('navigation.guidelines')
      })
    },
    refreshPages () {
      if (this.$route.name === 'BrandGuidelineList' || this.$route.name === 'BrandGuidelineDetail') {
        this.getBrandGuidelines()
      } else if (this.$route.name === 'BrandList' || this.$route.name === 'BrandDetail') {
        this.getBrandPages()
      }
    }
  },
  watch: {
    $route (to, from) {
      this.refreshPages()
    }
  },
  computed: {
    brandDetailRoute: function () {
      if (this.$route.name === 'BrandGuidelineList' || this.$route.name === 'BrandGuidelineDetail') {
        return 'BrandGuidelineDetail'
      }
      return 'BrandDetail'
    },
    overviewRoute: function () {
      if (this.$route.name === 'BrandGuidelineList' || this.$route.name === 'BrandGuidelineDetail') {
        return 'BrandGuidelineList'
      }
      return 'BrandList'
    }
  },
  mounted () {
    this.refreshPages()
  },
  components: { BrandNavigation }
}
</script>
